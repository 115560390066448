import Vue from 'vue'
import router from './router'
import data from './data'
import store from './store'
import content from './content'
import Site from './components/Site'
import regeneratorRuntime from "regenerator-runtime"
import Purecss from 'purecss'
import VueScrollReveal from 'vue-scroll-reveal';
import './assets/css/grids-responsive-min.css'
import './assets/css/main.css'

regeneratorRuntime
Purecss
Vue.config.productionTip = false
Vue.use(VueScrollReveal, {
  delay: 100,
  duration: 800,
  distance: '50px',
  scale: 0.97,
  reset: false
})

data().then( ([projects,pages,home]) => {
  content.site.projects = projects,
  content.site.archives = projects.filter( p => !p.excludeFromArchives ),
  content.site.about = pages.find( (p) => p.slug === 'about' )
  content.site.contact = pages.find( (p) => p.slug === 'contact' )
  content.site.home = home
  new Vue({
    router,
    store,
    content,
    render: h => h(Site)
  }).$mount('est')
}).catch( error => console.log(error) )