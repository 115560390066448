import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    menu: false
  },
  mutations: {
    toggleMenu (state) {
      state.menu = !state.menu
    },
    openMenu (state) {
      state.menu = true;
    },
    closeMenu (state) {
      state.menu = false;
    }
  }
})

export default store