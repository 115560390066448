<template>
  <div class="gallery pure-g gutters" v-if="gallery">
      <div v-for="media in gallery" 
          v-bind:class="['media', 'image', media.grid, media.video ? 'video' : null]" 
          v-bind:key="media.url" v-scroll-reveal>
          <img v-if="!media.video" :src="media.url" />
        <video v-if="media.video" width="100%" height="100%" loop autoplay playsinline muted>
          <source v-bind:src="media.url" type="video/mp4">
        </video>
      </div>
  </div>  
</template>

<script>
export default {
  name: 'Gallery',
  props: ['gallery'],
}
</script>

<style>
.gallery {
  position: relative;
  width: auto;
  padding-top: 30px;
  padding-bottom: 75px;
}
@media screen and (min-width: 48em) {
  .gallery {
    padding-top: 60px;
    padding-bottom: 80px;
  }
}

.gallery.pure-g.gutters {
  margin: 0 -5px;
}

.gallery.pure-g.gutters > [class *=pure-u]{
  box-sizing: border-box;
  padding: 2.5px 5px;
}

.gallery .previous, .gallery .next {
  position: absolute;
  width: 50%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
}

.gallery .previous {
  left: 0;
}

.gallery .next {
  right: 0;
}

.gallery video {
  height: auto;
}

</style>