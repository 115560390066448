<template>
  <div id="site-menu" v-bind:class="{ open: showMenu }" v-on:click="closeMenu">
    <nav>
      <ul>
        <li>
          <router-link class="link" to="/archive">Archive <div class="icon"><img src="images/icon_arrow.svg" /></div></router-link>
        </li>
        <li><router-link class="link" to="/studio">Studio <div class="icon"><img src="images/icon_arrow.svg" /></div></router-link>
        <li>
          <router-link class="link" to="/contact">Contact <div class="icon"><img src="images/icon_arrow.svg" /></div></router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  methods: {
    closeMenu: function () {
      this.$store.commit("closeMenu");
    },
  },
  computed: {
    showMenu: function () {
      return this.$store.state.menu == true;
    },
  },
};
</script>

<style>
#site-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  background-color: #fff;
  visibility: hidden;
}

#site-menu.open {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

#site-menu ul li {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-25px);
  transition-delay: 0,0,0;
  transition: transform 1.2s, opacity 0.0s, visibility 0.3s;
  -webkit-transition: transform 1.2s, opacity 0.0s, visibility 0.3s;
}

#site-menu.open ul li {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
  transition: transform 0.6s, opacity 0.6s, visibility 0.6s;
  -webkit-transition: transform 0.6s, opacity 0.6s, visibility 0.6s;
}
/* #site-menu ul li:nth-child(1) { transition-delay: 0s, 0s; } */
#site-menu.open ul li:nth-child(2) { transition-delay: 0.2s, 0.2s, 0.2s; }
#site-menu.open ul li:nth-child(3) { transition-delay: 0.4s, 0.4s, 0.4s; }
#site-menu.open ul li:nth-child(4) { transition-delay: 0.6s, 0.6s, 0.6s; }
#site-menu.open ul li:nth-child(5) { transition-delay: 0.8s, 0.8s, 0.8s; }

#site-menu nav {
  position: relative;
  transform: translateY(-50%);
  top: 50%;
  height: 225px;
  text-align: center;
  font-size: 44px;
  font-weight: normal;
  line-height: 44px;
}

@media screen and (min-width: 48em) {
  #site-menu nav {
    font-size: 85px;
    line-height: 85px;
    height: 295px;
  }
}

#site-menu ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  height: 100%;
  position: relative;
}
/* #site-menu.open ul {
  opacity: 1;
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
} */
#site-menu li {
  display: block;
  height: 50px;
  min-height: 50px;
}
@media screen and (min-width: 48em) {
  #site-menu li {
    height: 85px;
    min-height: 85px;
    margin-bottom: 20px;
  }
}

#site-menu li a {
  display: block;
  margin-right: 0;
}
#site-menu li a:hover {
  opacity: 0.75;
}

#site-menu li a:hover .icon {
   visibility: visible;
}
#site-menu li a .icon {
  position: absolute;
  margin-left: 10px;
  visibility: hidden;
}
#site-menu li .icon img {
  width: 1em;
  height: 1em;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>