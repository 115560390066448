<template>
  <div id="contact" class="page container">
    <div class="pure-g gutters">
      <div class="pure-u-1 pure-u-md-1 block">
        <h2 class="block-top">Contact</h2>
      </div>
      <div class="pure-u-1 pure-u-md-1-3 block mobile">
        <div class="body block-bottom" v-html="body"></div>
      </div>
      <div class="pure-u-1 pure-u-md-1-3 desktop">
        <div class="body block-bottom" v-html="body"></div>
      </div>
      <div class="pure-u-1 pure-u-md-1-3 enquiries block body-text">
        General inquiries<br>
        <a href="mailto:hello@establishednyc.com">hello@establishednyc.com</a>
      </div>
      <div class="pure-u-1 pure-u-md-1-3 jobs body-text">
        Jobs<br>
        <a href="mailto:jobs@establishednyc.com">jobs@establishednyc.com</a>
      </div>
      <div class="pure-u-1 block">
      </div>
      <div class="pure-u-1 pure-u-md-1-3 block body-text">
        <site-time city="NYC" timezone="America/New_York"></site-time>
      </div>
      <div class="pure-u-1 pure-u-md-2-3 address text-big">
        {{address_nyc}}<br>
        {{address_nyc_2}}<br>
        {{phone_nyc}}
      </div>
      <div class="pure-u-1 pure-u-md-1-3 block body-text">
        <site-time city="BCN" timezone="Europe/Paris"></site-time>
      </div>
      <div class="pure-u-1 pure-u-md-2-3 address text-big">
        {{address_bcn}}
      </div>
      <p class="newsletter"><a href="http://eepurl.com/A0jMn" target="_blank">{{contact.newsletter}}</a></p>
      <h5 v-html="contact.copyright"></h5>
    </div>
  </div>
</template>

<script>
import DataUtil from '../util/DataUtil'
import content from '../content'

export default {
  data: function() {
    return {
      contact: null,
      title: 'Contact',
      body: ``,
      image: 'images/contact.jpg',
      address_nyc: `584 Broadway, Nº 801`,
      address_nyc_2: `New York, NY 10012`,
      phone_nyc: `+1.212.633.0336`,
      address_bcn: `Carrer de Margarit 39,
2º-2ᵃ, 08004 Barcelona`
    }
  },
  beforeMount: function() {
    this.contact = content.site.contact;
  },
  mounted: function() {
    DataUtil.setTitle(this.contact.title);
    window.scrollTo(0,0);
  }
}
</script>

<style>
#contact {
  margin-top: 70px;
  font-size: 18px;
  line-height: 1.25em;
}
@media screen and (min-width: 48em) {
  #contact { 
    font-size: 24px;
  }
  #contact h2 {
    font-size: 44px;
    line-height: 1.15em;
  }
}
@media screen and (min-width: 64em) {
  #contact h2 {
    font-size: 85px;
  }
}
#contact .enquiries a, #contact .jobs a {
  text-decoration: underline;
}
@media screen and (min-width: 48em) {
  #contact {
    margin-top: 125px;
  }
}
#contact .body {
  /* top: 10px; */
  font-size: 18px;
  line-height: 1.25em;
  margin: 0;
  padding: 0;
  white-space: normal;
  position: relative;
  text-decoration: none;
  font-style: normal;
}
a {
  color: black;
}
#contact .block {
  padding-bottom: 10px;
}

#contact .mobile {
  display: block;
}
#contact .desktop {
  display: none;
}
@media screen and (min-width: 48em) {
  #contact .mobile {
    display: none;
  }  
  #contact .desktop {
    display: block;
    padding-bottom: 80px;
  }
}
#contact .enquiries {
  margin-top: 25px;  
}
#contact .body-text {
  font-size: 18px;
  line-height: 1.25em;
}
@media screen and (min-width: 48em) {
  #contact .enquiries {
    margin-top: 0;  
  }
  #contact .body {
    font-size: 18px;
    /* top: -92px;     */
    white-space: pre-line;
  }
  #contact .block {
    padding-bottom: 10px;
  }
}
@media screen and (min-width: 64em) {
  #contact .body-text {
    font-size: 24px;
  }
  #contact .body {
    font-size: 24px;
    /* top: -122px;     */
  }
}
.text-big {
  font-size: 44px;
  font-weight: normal;
  display: inline-block;
  line-height: 0.95em;
}
@media screen and (min-width: 48em) {
  .text-big {
    font-size: 48px;
    line-height: 1.15em;
  }
}
@media screen and (min-width: 64em) {
  .text-big {
    font-size: 85px;
    line-height: 90px;
  }
}
.address {
  margin-bottom: 25px;
  margin-top: -10px;
}
@media screen and (min-width: 48em) {
  .address {
    margin-bottom: 75px;
  }
}
</style>