const contentful = require('contentful')

const client = contentful.createClient({
  space: 'o168wnc4ptip',
  accessToken: '5878417cc5d05c73f0b85a3fe9e3fa69c14496e7982d84a5154c3af6bcdc1107',
  environment: 'master'
})

// const client = contentful.createClient({
//   space: 'o168wnc4ptip',
//   accessToken: '8d3ebb85df16ca0802fc73ee4112f9814503f7711e0b0c0f01a72df6ead0125b'
// })

export default function() {
  function getProjects() {
    return new Promise( (resolve) => {
      client.getEntries({ content_type: 'project' }).then( response => {
        let projects = [];
        response.items.forEach( (project) => {
          let data = {
            title: project.fields.title,
            slug: project.fields.slug,
            date: project.fields.date,
            subtitle: project.fields.subtitle,
            description: project.fields.description,
            credits: project.fields.credits,
            order: project.fields.order,
            excludeFromArchives: project.fields.excludeFromArchives,
            excludeAwardsFromStudio: project.fields.excludeAwardsFromStudio
          }
          if (project.fields.thumbnail && project.fields.thumbnail.fields && project.fields.thumbnail.fields.file && project.fields.thumbnail.fields.file.url) {
            data.thumbnail = project.fields.thumbnail.fields.file.url
          }
          if (project.fields.feature && project.fields.feature.fields.file && project.fields.feature.fields.file.url) {
            data.feature = project.fields.feature.fields.file.url
          }
          data.gallery = [];
          if (project.fields.gallery) {
            project.fields.gallery.map( (item) => { 
              if (!item.fields) return;
              let grid = 'pure-u-1';
              let caption = item.fields.description?.toLowerCase() || '';
              if (caption.includes('half')) grid = "pure-u-1-2";
              else if (caption.includes('third')) grid = "pure-u-1-3";
              else if (caption.includes('quarter')) grid = "pure-u-1-4";
              data.gallery.push({
                url: item.fields.file.url,
                caption: item.fields.description?.toLowerCase() || '',
                grid: grid, 
                video: item.fields.file.contentType === 'video/mp4'
              })
            })
          }
          data.awards = [];
          if (project.fields.awards) {
            project.fields.awards.map( (item) => { 
              data.awards.push({
                title: item.fields.title,
                year: item.fields.year,
                id: item.sys.id
              })
            })
          }
          projects.push(data);
        })
        projects.sort( (a, b) => {
          let o1 = a.order || -1
          let o2 = b.order || -1
          if (o1 < 0 && o2 > 0) return 1
          if (o1 > 0 && o2 < 0) return -1
          if (o1 > o2) return 1
          if (o1 < o2) return -1
          if (new Date(a.date) > new Date(b.date)) return -1
          if (new Date(a.date) < new Date(b.date)) return 1
          return 0
        })
        resolve(projects);
      })
    })    
  }

  function getPages() {
    return new Promise( (resolve) => {
      client.getEntries({ content_type: 'page' }).then( response => {
        let pages = [];
        response.items.forEach( (page) => {
          let data = {
            title: page.fields.title,
            slug: page.fields.slug,
            text: page.fields.text,
            image: page.fields.image
          }
          pages.push(data)
        });
       resolve(pages);
      })
    })
  }

  function getHomeBlocks() {
    return new Promise( (resolve) => {
      client.getEntries({ content_type: 'home' }).then( response => {        
        let homeBlocks = [];
        response.items.forEach( item => {
          let block = {}
          block.id = item.sys.id;
          block.layout = item.fields.layout
          block.row =  item.fields.row;
          block.projects = [];
          item.fields.projects && item.fields.projects.forEach( (project) => {
            if (!project.fields) return;
            let image = project.fields.homepage?.fields?.file?.url || '';
            let data = {
              id: project.sys.id,
              title: project.fields.title,
              slug: project.fields.slug,
              description: project.fields.description,
              subtitle: project.fields.subtitle,
              homepageSubtitle: project.fields.homepageSubtitle,
              image
            }
            block.projects.push(data)
          });
          homeBlocks.push(block);
        })
        homeBlocks = homeBlocks.sort( (a, b) => a.row - b.row )
        resolve(homeBlocks);
      })
    })
  }

  return Promise.all([
    getProjects(),
    getPages(),
    getHomeBlocks()
  ])
}