<template>
  <header id="header">
    <div class="container" v-on:click="click">
      <router-link id="logo" class="link" to="/">ESTABLISHED.</router-link>
    </div>
  </header>
</template>

<script>
export default {
  methods: {
    click () {
      this.$store.commit('closeMenu')
    }
  }
}
</script>

<style>
#header {
  position: fixed;
  z-index: 10;
  width: 100vw;
  top: 0;
  /* background-color: white; */
  box-sizing: border-box;
  font-size: 18px;
  padding-top: 18px;
}
#header .container {
  padding-top: 0;
}
@media screen and (min-width: 48em) {
  #header {
    position: fixed;
    font-size: 1.5rem;
    padding-top: 20px;
  }
}
#logo {
  float: left;
  letter-spacing: -0.05rem;
}
#location {
  text-align: left;
}
@media screen and (min-width: 48em) {
  #location {
    text-align: right;
  }
}
nav {
  overflow: hidden;
  text-align: left;
}
@media screen and (min-width: 48em) {
  nav {
    text-align: center;
  }
}
nav .link {
  margin-right: 1em;
}
.link {
  color: black;
  text-decoration: none;
}
</style>