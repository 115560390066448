<template>
  <div id="site" class="site">
    <site-menu></site-menu>
    <site-menu-icon></site-menu-icon>
    <site-header></site-header>
    <transition name="site">
      <router-view :key="$route.fullPath"></router-view>
    </transition>
    <site-scroll></site-scroll>
    <site-footer></site-footer>
  </div>  
</template>

<script>
import Vue from 'vue'
import SiteHeader from './SiteHeader'
import SiteFooter from './SiteFooter'
import SiteMenu from './SiteMenu'
import SiteMenuIcon from './SiteMenuIcon'
import ProjectGrid from './ProjectGrid'
import SiteScroll from './SiteScroll'
import SiteTime from './SiteTime'

Vue.component('site-header', SiteHeader)
Vue.component('site-footer', SiteFooter)
Vue.component('site-menu', SiteMenu)
Vue.component('site-menu-icon', SiteMenuIcon)
Vue.component('project-grid', ProjectGrid)
Vue.component('site-scroll', SiteScroll)
Vue.component('site-time', SiteTime)

export default {
  data () {
    return {
      timeout: null
    }
  },
  methods: {
    resize() {
      this.timeout = setInterval( () => {
        window.dispatchEvent(new Event('resize'));
      }, 5000)
    }
  },
  created () {
    this.resize();
  }
}
</script>

<style>
body {
  padding-top: 20px;
}
@media screen and (min-width: 48em) {
  body {
    padding-top: 0;
  }
}
</style>