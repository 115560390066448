import Vue from 'vue'
import Router from 'vue-router'
import Home from './components/Home'
import Projects from './components/Projects'
import Project from './components/Project'
import Studio from './components/Studio'
import Contact from './components/Contact'

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', component: Home },
    { path: '/archive/', component: Projects },
    { path: '/studio/', component: Studio },
    { path: '/contact/', component: Contact },
    { path: '/:project/', component: Project }    
  ]
})

export default router