<template>
  <div class="page studio container">
    <div class="pure-g gutters" id="about" v-if="about">
      <div class="pure-u-1 pure-u-md-1-3 block">
        <h2 class="block-top">Studio</h2>
        <div class="subtitle block-bottom" v-html="text"></div>
      </div>
      <div class="pure-u-1 pure-u-md-2-3 image">
        <img :src="image"/>
      </div>
    </div>
    <div class="pure-g gutters" id="awards" v-if="awards">
      <h2 class="pure-u-1">Awards</h2>
      <div v-for="year in awards" v-bind:key="year.year" class="pure-u-1">
        <h3 class="year pure-u-1">{{ year.year }}</h3>
        <div class="award" v-for="award in year.awards" v-bind:key="award.id">
          <div class="pure-u-md-1-3"></div>
          <div class="pure-u-md-1-3"><div class="award-award">{{ award.award }}</div></div>
          <div class="pure-u-md-1-3">
            <div class="award-project">
              <router-link :to="{path: '/' + award.project.slug + '/'}">
                <span class="project">{{ award.project.title }}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {markdown} from 'markdown'
import DataUtil from '../util/DataUtil'
import content from '../content'

export default {
  data: function() {
    return {
      about: null,
      image: null,
      projects: null,
      awards: null
    }
  },
  computed: {
    text: function() {
      return markdown.toHTML(this.about.text)
    }
  },
  mounted: function() {
    this.about = content.site.about
    this.image = this.about.image.fields.file.url
    this.projects = content.site.projects
    this.awards = DataUtil.awardsByYearAll(this.projects);
    DataUtil.setTitle(this.about.title);
    window.scrollTo(0,0);
  }
}
</script>

<style>
#about {
    margin-top: 70px;
    position: relative;

}
@media screen and (min-width: 48em) {
  #about {
    margin-top: 125px;
  }
}
#about .subtitle {
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
  white-space: normal;
  font-size: 18px;
  line-height: 1.15em;
  padding-right: 30px;
}
@media screen and (min-width: 48em) {
  #about .subtitle {
    position: absolute;
    margin-bottom: 0;
    bottom: 0;
    font-size: 18px;
  }
}
@media screen and (min-width: 64em) {
  #about .subtitle {
    font-size: 24px;
  }
}
@media screen and (min-width: 48em) {
  #about h2, #awards h2 {
    font-size: 44px;
    line-height: 1.15em;
  }
}
@media screen and (min-width: 64em) {
  #about h2, #awards h2 {
    font-size: 85px;
  }
}
#about .subtitle p {
  margin-bottom: 0;
}
#about .block {
  margin-bottom: 2px;
}
.clear-height {
  height: auto;
}
@media screen and (min-width: 48em) {
  .clear-height {
    height: 0;
  }
}
@media screen and (min-width: 48em) {
  #about .subtitle {
    white-space: pre-line;
    bottom: 0;
  }
}
#about .body {
  white-space: pre-line;  
}
h2 {
  margin: 0;
}

h3.year {
  border-bottom: none;
  padding-top: 0.5em;
  border-top: 1px solid #ccc;
  height: auto;
  line-height: 2em;
  margin-top: 1em;
  margin-bottom:  1em;
  letter-spacing: 2px;
  font-size: 12px;
  font-style: italic;
}
@media screen and (min-width: 48em) {
  h3.year {
    height: 0;
    margin-bottom: 0;
  }
}
#about .award {
  margin-bottom: 20px;
}
.award .award-award {
  padding-right: 20px;
  padding-left: 0;  
  line-height: 1.15em;
}
.award .award-project {
  padding-left: 0;
  margin-bottom: 10px;
  line-height: 1.15em;
}
@media screen and (min-width: 48em) {
  .award {
    line-height: 1.3em;
    font-size: 18px;
  }
  .award .award-award {
    margin-bottom: 10px;
  }
  .award .award-project {
    padding-left: 0;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 64em) {
  .award {
    line-height: 1.15em;
    font-size: 24px;
  }
  .studio .award {
    line-height: 1.7em;
    font-size: 24px;
  }
}
#awards {
  margin-bottom: 50px;
}
#awards h2 {
  margin-top: 40px;  
  margin-bottom: 20px;  
}
@media screen and (min-width: 48em) {
  #awards h2 {
    margin-top: 50px;  
    margin-bottom: 20px;  
  }
}

@media screen and (min-width: 64em) {
  #awards h2 {
    margin-top: 100px;  
    margin-bottom: 40px;  
  }
}

#awards a {
  text-decoration: underline;
}
.block {
  position: relative;
}
</style>