<template>
    <div class="time">
      {{time}} <div class="icon"><img :src="icon" /></div>
    </div>
</template>

<script>
var SunCalc = require('suncalc');
export default {
  props: ['city', 'timezone'],
  data: function() {
    return {
      time: null,
      day: null,
      timer: null
    }
  },
  mounted () {
    this.timer = setInterval(this.updateTime, 1000)
    this.updateTime();
  },
  beforeDestroy () {
    clearInterval( this.timer )
  },
  computed: {
    icon: function() {
      if (this.day) return 'images/icon_day.svg';
      return 'images/icon_night.svg';
    }
  },
  methods: {
    updateTime () {
      let now = new Date();
      let time = now.toLocaleTimeString('en-US', { timeZone: this.timezone, timeStyle: 'short' });
      let display = time.split(' ');
      let coords = '';
      if (this.city == 'BCN') coords = [41.372461,2.1610755];
      else coords = [40.7189608,-74.0049825];
      let times = SunCalc.getTimes(new Date(), coords[0], coords[1]);
      if (now > times.sunrise && now < times.sunset) {
        this.day = true;
      } else {
        this.day = false;
      }
      this.time = `${this.city} ${display[0]} ${display[1]}`;
    }
  }
}
</script>

<style>
.icon {
  position: relative;
  top: 4px;
  display: inline-flex;
  align-self: center;
}
.icon img {
  width: 1em;
  height: 1em;
}
</style>