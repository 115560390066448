<template>
    <div id="site-scroll" v-on:click="scrollToTop" v-bind:class="{ show: visible, isBottom: isBottom }">
        <div class="circle"></div>
        <div class="arrow"></div>
    </div>
</template>

<script>
export default {
  data: function() {
    return {
        scrollTop: 0,
        isBottom: false
    }
  },
  computed: {
      visible: function() {
          if (this.scrollTop > window.innerHeight) return true;
          return false;
      }
  },
  mounted() {
    window.onscroll = this.onScroll;
  },
  methods: {
    onScroll() {
      this.scrollTop = document.documentElement.scrollTop;
      this.isBottom = this.scrollTop > document.documentElement.scrollHeight - document.documentElement.clientHeight - 50;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  },
}
</script>

<style>
#site-scroll {
  position: fixed;
  cursor: pointer;
  width: 75px;
  height: 75px;
  bottom: 140px;
  right: 20px;
  transform: translateY(215px);
  transition: transform 0.4s ease-in-out 0s;
  z-index: 100;
}

#site-scroll.show {
  transform: translateY(75px);
}

#site-scroll.isBottom {
  transform: translateY(-70px);
}

#site-scroll .circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 75px;
  height: 75px;
  background-image: url('/images/scroll_circle.png');
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out 0s;
}
#site-scroll:hover .circle {
  animation: spin 6s linear infinite;
}
#site-scroll .arrow {
  position: absolute;
  top: 0;
  left: 0;
  width: 75px;
  height: 75px;
  background-image: url('/images/scroll_arrow.png');
  background-size: 21px 28px;
  background-repeat: no-repeat;
  background-position: center center;
}
@media screen and (min-width: 48em) {
  #site-scroll {
    right: 30px;
    width: 100px;
    height: 100px;
    bottom: 30px;
    transform: translateY(130px);
  }    
  #site-scroll .circle {
    width: 100px;
    height: 100px;
  }
  #site-scroll .arrow {
    width: 100px;
    height: 100px;
    background-size: 28px 37px;
  }
  #site-scroll.show {
    transform: translateY(0);
  }
  #site-scroll.isBottom {
    transform: translateY(-105px);
  }
}
@media screen and (min-width: 64em) {
  #site-scroll {
    right: 40px;
  }
}
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
</style>