<template>
  <div id="home">
    <div class="container">
      <div id="splash">
        <h2>ESTABLISHED is a full-service creative agency in New York, specializing in creating new brands from scratch, and rejuvenating existing ones.</h2>
      </div>

      <div class="pure-u-1 pure-u-md-1">
        <div v-for="block in blocks" :key="block.id">
          
          <!-- LAYOUT 1 -->
          <div class="pure-u-1 pure-u-md-24-24" v-if="block.layout == 'Layout 1'">
            <div v-for="(project, index) in block.projects" :key="project.id" >
              <div v-if="index == 0" class="work pure-u-1 pure-u-md-24-24" v-scroll-reveal>
                <router-link :to="project.slug">
                  <img v-if="!project.image.includes('mp4')" v-bind:src="project.image">
                  <video v-if="project.image.includes('mp4')" width="100%" height="100%" loop autoplay playsinline muted>
                    <source v-bind:src="project.image" type="video/mp4">
                  </video>
                  <h3>{{ project.title }}</h3>
                  <h4>{{ project.homepageSubtitle || project.subtitle }}</h4>
                </router-link>
              </div>
            </div>
          </div>

          <!-- LAYOUT 2 -->
          <div class="pure-u-1 pure-u-md-1" v-if="block.layout == 'Layout 2'">
            <span v-for="(project, index) in block.projects" :key="project.id">
              <div v-if="index == 0" class="work pure-u-1 pure-u-md-4-24"></div>
              <div v-if="index == 0" class="work pure-u-1 pure-u-md-16-24" v-scroll-reveal>
                <router-link :to="project.slug">
                  <img v-if="!project.image.includes('mp4')" v-bind:src="project.image">
                  <video v-if="project.image.includes('mp4')" width="100%" height="100%" loop autoplay playsinline muted>
                    <source v-bind:src="project.image" type="video/mp4">
                  </video>
                  <h3>{{ project.title }}</h3>
                  <h4>{{ project.homepageSubtitle || project.subtitle }}</h4>
                </router-link>
              </div>
            </span>                
          </div>  

          <!-- LAYOUT 3A -->
          <div class="pure-u-1 pure-u-md-1" v-if="block.layout == 'Layout 3A'">
            <span v-for="(project, index) in block.projects" :key="project.id">
              <div v-if="index == 0" class="work pure-u-1 pure-u-md-14-24" v-scroll-reveal>
                <router-link :to="project.slug">
                  <img v-if="!project.image.includes('mp4')" v-bind:src="project.image">
                  <video v-if="project.image.includes('mp4')" width="100%" height="100%" loop autoplay playsinline muted>
                    <source v-bind:src="project.image" type="video/mp4">
                  </video>
                  <h3>{{ project.title }}</h3>
                  <h4>{{ project.homepageSubtitle || project.subtitle }}</h4>
                </router-link>
              </div>
              <div v-if="index == 0" class="work pure-u-1 pure-u-md-4-24"></div>
              <div v-if="index == 1" class="work pure-u-1 pure-u-md-6-24" v-scroll-reveal>
                <router-link :to="project.slug">
                  <img v-if="!project.image.includes('mp4')" v-bind:src="project.image">
                  <video v-if="project.image.includes('mp4')" width="100%" height="100%" loop autoplay playsinline muted>
                    <source v-bind:src="project.image" type="video/mp4">
                  </video>
                  <h3>{{ project.title }}</h3>
                  <h4>{{ project.homepageSubtitle || project.subtitle }}</h4>
                </router-link>
              </div>
            </span>                
          </div>

          <!-- LAYOUT 3B -->
          <div class="pure-u-1 pure-u-md-1" v-if="block.layout == 'Layout 3B'">
            <span v-for="(project, index) in block.projects" :key="project.id">
              <div v-if="index == 0" class="work pure-u-1 pure-u-md-6-24" v-scroll-reveal>
                <router-link :to="project.slug">
                  <img v-if="!project.image.includes('mp4')" v-bind:src="project.image">
                  <video v-if="project.image.includes('mp4')" width="100%" height="100%" loop autoplay playsinline muted>
                    <source v-bind:src="project.image" type="video/mp4">
                  </video>
                  <h3>{{ project.title }}</h3>
                  <h4>{{ project.homepageSubtitle || project.subtitle }}</h4>
                </router-link>
              </div>
              <div v-if="index == 0" class="work pure-u-1 pure-u-md-4-24"></div>
              <div v-if="index == 1" class="work pure-u-1 pure-u-md-14-24">
                <router-link :to="project.slug">
                  <img v-if="!project.image.includes('mp4')" v-bind:src="project.image">
                  <video v-if="project.image.includes('mp4')" width="100%" height="100%" loop autoplay playsinline muted>
                    <source v-bind:src="project.image" type="video/mp4">
                  </video>
                  <h3>{{ project.title }}</h3>
                  <h4>{{ project.homepageSubtitle || project.subtitle }}</h4>
                </router-link>
              </div>
            </span>                
          </div>  

          <!-- LAYOUT 4A -->
          <div class="pure-u-1 pure-u-md-1" v-if="block.layout == 'Layout 4A'" >
            <span v-for="(project, index) in block.projects" :key="project.id">
              <div v-if="index == 0" class="work pure-u-1 pure-u-md-4-24" v-scroll-reveal></div>
              <div v-if="index == 0" class="work pure-u-1 pure-u-md-20-24" v-scroll-reveal>
                <router-link :to="project.slug">
                  <img v-if="!project.image.includes('mp4')" v-bind:src="project.image">
                  <video v-if="project.image.includes('mp4')" width="100%" height="100%" loop autoplay playsinline muted>
                    <source v-bind:src="project.image" type="video/mp4">
                  </video>
                  <h3>{{ project.title }}</h3>
                  <h4>{{ project.homepageSubtitle || project.subtitle }}</h4>
                </router-link>
              </div>
            </span>                
          </div>    

          <!-- LAYOUT 4B -->
          <div class="pure-u-1 pure-u-md-1" v-if="block.layout == 'Layout 4B'">
            <span v-for="(project, index) in block.projects" :key="project.id">
              <div v-if="index == 0" class="work pure-u-1 pure-u-md-20-24" v-scroll-reveal>
                <router-link :to="project.slug">
                  <img v-if="!project.image.includes('mp4')" v-bind:src="project.image">
                  <video v-if="project.image.includes('mp4')" width="100%" height="100%" loop autoplay playsinline muted>
                    <source v-bind:src="project.image" type="video/mp4">
                  </video>
                  <h3>{{ project.title }}</h3>
                  <h4>{{ project.homepageSubtitle || project.subtitle }}</h4>
                </router-link>
              </div>
              <div v-if="index == 0" class="work pure-u-1 pure-u-md-4-24"></div>              
            </span>                
          </div>              

          <!-- LAYOUT 5A -->
          <div class="pure-u-1 pure-u-md-1" v-if="block.layout == 'Layout 5A'">
            <span v-for="(project, index) in block.projects" :key="project.id">
              <div v-if="index == 0" class="work pure-u-1 pure-u-md-4-24"></div>
              <div v-if="index == 0" class="work pure-u-1 pure-u-md-6-24" v-scroll-reveal>
                <router-link :to="project.slug">
                  <img v-if="!project.image.includes('mp4')" v-bind:src="project.image">
                  <video v-if="project.image.includes('mp4')" width="100%" height="100%" loop autoplay playsinline muted>
                    <source v-bind:src="project.image" type="video/mp4">
                  </video>
                  <h3>{{ project.title }}</h3>
                  <h4>{{ project.homepageSubtitle || project.subtitle }}</h4>
                </router-link>
              </div>
              <div v-if="index == 0" class="work pure-u-1 pure-u-md-2-24"></div>
              <div v-if="index == 1" class="work pure-u-1 pure-u-md-8-24" v-scroll-reveal>
                <router-link :to="project.slug">
                  <img v-if="!project.image.includes('mp4')" v-bind:src="project.image">
                  <video v-if="project.image.includes('mp4')" width="100%" height="100%" loop autoplay playsinline muted>
                    <source v-bind:src="project.image" type="video/mp4">
                  </video>
                  <h3>{{ project.title }}</h3>
                  <h4>{{ project.homepageSubtitle || project.subtitle }}</h4>
                </router-link>
              </div>
            </span>                
          </div> 

          <!-- LAYOUT 5B -->
          <div class="pure-u-1 pure-u-md-1" v-if="block.layout == 'Layout 5B'">
            <span v-for="(project, index) in block.projects" :key="project.id">
              <div v-if="index == 0" class="work pure-u-1 pure-u-md-4-24"></div>
              <div v-if="index == 0" class="work pure-u-1 pure-u-md-8-24" v-scroll-reveal>
                <router-link :to="project.slug">
                  <img v-if="!project.image.includes('mp4')" v-bind:src="project.image">
                  <video v-if="project.image.includes('mp4')" width="100%" height="100%" loop autoplay playsinline muted>
                    <source v-bind:src="project.image" type="video/mp4">
                  </video>
                  <h3>{{ project.title }}</h3>
                  <h4>{{ project.homepageSubtitle || project.subtitle }}</h4>
                </router-link>
              </div>
              <div v-if="index == 0" class="work pure-u-1 pure-u-md-2-24"></div>
              <div v-if="index == 1" class="work pure-u-1 pure-u-md-6-24" v-scroll-reveal>
                <router-link :to="project.slug">
                  <img v-if="!project.image.includes('mp4')" v-bind:src="project.image">
                  <video v-if="project.image.includes('mp4')" width="100%" height="100%" loop autoplay playsinline muted>
                    <source v-bind:src="project.image" type="video/mp4">
                  </video>
                  <h3>{{ project.title }}</h3>
                  <h4>{{ project.homepageSubtitle || project.subtitle }}</h4>
                </router-link>
              </div>
            </span>                
          </div>       
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataUtil from '../util/DataUtil'
import content from '../content'
export default {
  mounted: function() {
    window.scrollTo(0,0);
    DataUtil.setTitle('');
  },
  computed: {
    blocks: function() {
      return content.site.home
    }
  }
}
</script>
<style>
@media screen and (max-width: 48em) {
  #home .work {
    width: auto;
  }
}
#splash {
    margin-top: 70px;
    margin-bottom: 60px;
}
#splash h2 {
    line-height: 1.05em;  
}


@media screen and (min-width: 48em) {
  #splash h2 {
    font-size: 44px;
    line-height: 1.15em;
  }
  #splash {
    margin-top: 100px;
    margin-bottom: 75px;
  }
}
@media screen and (min-width: 64em) {
  #splash h2 {
    font-size: 85px;
  }
  #splash {
    margin-top: 200px;
    margin-bottom: 200px;
  }
}

@media screen and (min-width: 64em) {
  #splash {
    margin-top: 200px;
    margin-bottom: 200px;
  }
}
#splash h2 a {
  text-decoration: underline;
}
#splash h2 .underline {
  text-decoration: underline;
}
#home h3 {
  margin-top: 10px;
  margin-bottom: 0;
  text-decoration: underline;
}
#home h4 {
  text-decoration: none import !important;
}
#home .work {
  margin-bottom: 40px;
}
@media screen and (min-width: 48em) {
  #home .work {
    margin-bottom: 75px;
  }
}

</style>