<template>
  <div id="project-grid">
    <div class="pure-g gutters" v-if="site.projects">
      <div class="heading pure-u-1-2 pure-u-sm-1-2 pure-u-md-1-3 project"><h2>Archive</h2></div>
      <div class="pure-u-1-2 pure-u-sm-1-2 pure-u-md-1-3 project" v-for="project in site.archives" v-bind:key="project.slug">
        <router-link class="link" :to="{path: '/' + project.slug + '/'}" v-if="currentProject !== project.slug">
          <div class="image-wrapper">
            <div v-if="project.thumbnail" class="image" v-bind:style="{ backgroundImage: 'url(' + project.thumbnail + ')' }">
            </div>
            <div v-if="!project.thumbnail" class="image" v-bind:style="{ backgroundImage: 'url(' + project.gallery && project.gallery[0] && project.gallery[0].url + ')' }">
            </div>
          </div>
          <h3 class="title" v-if="project.title">{{ project.title }}</h3>
          <h4 class="subtitle">{{ project.subtitle }}</h4>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import content from '../content'

export default {
  data: function() {
    return {
      site: content.site,
      currentProject: null
    }
  },
  mounted: function() {
    this.currentProject = this.$route.params.project
  }
}
</script>

<style>
#project-grid .heading {
  display: none;
}
@media screen and (min-width: 48em) {
  #project-grid .heading {
    display: block;
  }
}
.project h3 {
  margin-top: 15px;
  text-decoration: underline;
}
.project h2 {
  margin-top: 0;
}
.project {
  margin-bottom: 40px;
  min-height: 150px;
}
.project .image-wrapper {
  position: relative;
  top: 0;
  overflow: hidden;
}
.project .image {
  position: relative;
  top: 0;
  width: 100%;
  padding-top: 72.72%;
  background-size: cover;
  background-position: center center;
  transition: transform 0.4s ease-out, opacity 0.4s ease-out;
}
.project .link:hover .image {
  transform: scale(1.03);
  opacity: 0.8;
}
</style>