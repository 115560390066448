<template>
  <div
    id="menu-icon"
    v-bind:class="{ open: menuOpen }"
    v-bind:style="{ right: right +'px' }" 
    v-on:click="toggleMenu">
      <img src="images/plus.png" alt="Open Menu" />
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: function() {
    return {
      right: 0
    }
  },
  mounted () {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  },
  computed: mapState({
    menuOpen: (state) => state.menu,
  }),
  methods: {
    toggleMenu() {
      this.$store.commit("toggleMenu");
    },
    onResize: function() {
      const w = window.innerWidth;
      if (w > 1920 + 50) this.right = (w - 1920)/2;
      else if (w > 1024) this.right = 40;
      else if (w > 728) this.right = 30;
      else this.right = 20;
    }
  },
};
</script>

<style>
#menu-icon {
  position: fixed;
  top: 21px;
  right: 25px;
  width: 19px;
  height: 20px;
  z-index: 21;
  font-size: 50px;
  font-weight: normal;
  cursor: pointer;
  transition: transform 0.4s ease-in-out;
  transform: rotate(0deg);
  transform-origin: 50% 50%;
  padding: 0;
}
#menu-icon.open {
  transform: rotate(135deg) scale(1);
}
#menu-icon img {
  position: absolute;
  width: 19px;
  height: 20px;
}
</style>