<template>
  <div id="project" class="container" key="project.name">
    <div v-if="project">
      <div id="featured">
        <img v-if="!project.feature.includes('mp4')" v-bind:src="project.feature">
        <video v-if="project.feature.includes('mp4')" width="100%" height="100%" loop autoplay playsinline muted>
          <source v-bind:src="project.feature" type="video/mp4">
        </video>
      </div>
      <div id="body">
        <div class="pure-u-1 pure-u-md-12-24">
          <h2>{{ project.title }}</h2>  
          <div class="subtitle block-bottom">
            <h3>{{ project.subtitle }}</h3>
          </div>
        </div>
        <div class="pure-u-1 pure-u-md-12-24">
          <p class="body">{{ project.description }}</p>
          <ul class="awards" v-if="project.awards">
            <div class="year" v-for="year in awardsByYear" v-bind:key="year.year">
              <h5>{{year.year}}</h5>
              <li class="award" v-for="award in year.awards" v-bind:key="award.id">{{ award }}</li>
            </div>
          </ul>
          <div class="credits" v-if="project.credits">
            <h5>Credits</h5>
            <div v-html="project.credits"></div>
          </div>
        </div>
      </div>
      <gallery :gallery="project.gallery"></gallery>
    </div>
    <div id="next" class="pure-g gutters">
      <div class="pure-u-1 pure-u-md-1-3">
        <h2>Next <div class="icon"><img src="images/icon_arrow.svg"></div></h2>
      </div>
      <div class="pure-u-1 pure-u-md-1-3 project" v-for="project in next" v-bind:key="project.slug">

        <router-link class="link" :to="{path: '/' + project.slug + '/'}" v-if="currentProject !== project.slug">
          <div class="image-wrapper">
            <div v-if="project.thumbnail" class="image" v-bind:style="{ backgroundImage: 'url(' + project.thumbnail + ')' }">
            </div>
            <div v-if="!project.thumbnail" class="image" v-bind:style="{ backgroundImage: 'url(' + project.gallery[0].url + ')' }">
            </div>
          </div>
          <h3 class="title" v-if="project.title">{{ project.title }}</h3>
          <h4 class="subtitle">{{ project.subtitle }}</h4>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Gallery from './Gallery'
// import ProjectGrid from './ProjectGrid'
import DataUtil from '../util/DataUtil'
import content from '../content'

export default {
  components: {
    gallery: Gallery,
    // ProjectGrid
  },
  data: function() {
    return {
      project: null,
      site: content.site,
      awardsByYear: null,
      next: null,
      currentProject: null
    }
  },
  mounted: function() {
    window.scrollTo(0,0);
    window.addEventListener('resize', this.onResize);
    this.update();
    this.onResize();
  },
  methods: {
    update: function() {
      let path = this.$router.currentRoute.path
      path = path.split('/')[1]
      if (content.site.projects) {
        let project = content.site.projects.find( (p) => path === p.slug )
        if (project) {
          project.subtitle = project.subtitle && project.subtitle.split(',').map(i => i.trim()).join('\n');
          project.feature = project.feature || project.homepage || project.thumbnail;
          this.project = project;
        }
      }
      if (this.project) {
        this.awardsByYear = DataUtil.awardsByYear(this.project.awards);
        DataUtil.setTitle(this.project.title);
      }
      let index = content.site.projects.map( p => p.slug ).indexOf(path)
      if (this.project.excludeFromArchives) index = 0;
      let next = [];
      while (next.length < 2) {
        index++;
        if (index > content.site.projects.length - 1) index = 0;
        let p = content.site.projects[index];
        if (!p.excludeFromArchives) {
          next.push(p);
        }
      }
      this.next = next;
    },
    onResize: function() {
      // console.log(this.)
    }
  }
}
</script>

<style>
#project {
  position: relative;
  margin-top: 70px;
}
@media screen and (min-width: 48em) {
  #project {
    margin-top: 125px;
  }
}
#body {
  position: relative;
  min-height: 200px;
}
#body .subtitle {
  font-size: 18px;
  margin: 0;
  padding: 0;
  white-space: normal;
  position: relative;
  font-style: italic;
  letter-spacing: 2px;
  margin-bottom: 25px;
}
#project .body {
  font-style: normal;
  white-space: pre-line;
  margin-top: 0;
  line-height: 1.15em;
}

@media screen and (min-width: 48em) {
  #body .subtitle {
    white-space: pre-line;
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
  }
  #project .body {
    font-size: 18px;
  }
}
#featured {
  margin-bottom: 40px;
  padding-right: 0;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
#project h2 {
  margin-left: -5px;
  margin-top: -10px;
  margin-bottom: 12px;
  overflow-wrap: break-word;
  font-size: 44px;
  line-height: 1em;
}

@media screen and (min-width: 48em) {
  #project h2 {
    margin-top: -5px;
    font-size: 44px;
  }
}

@media screen and (min-width: 64em) {
  #project h2 {
    font-size: 85px;
  }
  #project .body {
    font-size: 24px;
  }

}

#body h3 {
  font-size: 11px;
  line-height: 1.25em;
  font-style: italic;
  margin: 0;
  padding: 0;
  white-space: pre-line;
}
#body h5 {
  padding: 0;
  margin: 1em 0 0 0;
  font-size: 11px;
  font-weight: normal;
  font-style: italic;
  letter-spacing: 2px;
}
@media screen and (min-width: 48em) {
  #body h3 {
    font-size: 12px;
    line-height: 16px;
  }  
  #body h5 {
    font-size: 12px;
  }
}

#next h2 {
  display: inline-flex;
}
#next .icon {
  display: inline-flex;
  margin-left: 10px;
}
#next .icon img {
  width: 1em;
  height: 1em;
}
.project .image-wrapper {
  position: relative;
  top: 0;
  overflow: hidden;
}
.project .image {
  position: relative;
  top: 0;
  width: 100%;
  padding-top: 72.72%;
  background-size: cover;
  background-position: center center;
  transition: transform 0.4s ease-out, opacity 0.4s ease-out;
}
.project .link:hover .image {
  transform: scale(1.03);
  opacity: 0.8;
}
</style>