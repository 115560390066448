import content from '../content'

export default {
  awardsByYearAll: (projects) => {
    if (!projects) return null;
    let results = [];
    projects.forEach( (project) => {
      if (project.excludeAwardsFromStudio) return;
      if (project.awards) project.awards.forEach( (award) => {
        // let year = award.award.slice(0, 4);
        // let title = award.award.slice(5);
        let year = award.year
        let title = award.title
        if (year) {
          let found = false;
          results.forEach( (result) => {
            if (result.year === year) {
              result.awards.push({
                project,
                award: title
              });
              found = true;
            }
          });
          if (!found) {
            results.push({
              year: year,
              awards: [{
                project,
                award: title
              }]
            });
          }
        }
      });
    });
    results.sort( (a, b) => {
        return Number(b.year) - Number(a.year);
    });
    return results;
  },
  awardsByYear: (awards) => {
    if (!awards) return null;
    let results = [];
    awards.forEach( (award) => {
      // let year = award.award.slice(0, 4);
      // let title = award.award.slice(5);
      let year = award.year
      let title = award.title
      if (year) {
        let found = false;
        results.forEach( (result) => {
          if (result.year === year) {
            result.awards.push(title);
            found = true;
          }
        });
        if (!found) {
          results.push({
            year: year,
            awards: [title]
          });
        }
      }
    });
    return results;
  },
  setTitle: (section) => {
    if (section) {
      document.title = section + ' | ' + content.site.title;
    } else {
      document.title = content.site.title;
    }
  }
};
