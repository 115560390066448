<template>
  <div id="projects">
    <div class="container">
      <h2 class="mobile">Archive</h2>
      <project-grid></project-grid>
    </div>
  </div>
</template>

<script>
import ProjectGrid from './ProjectGrid'
import DataUtil from '../util/DataUtil'
export default {
  components: {
    ProjectGrid
  },
  mounted: function() {
    let site = document.getElementById('site')
    if (site) site.scrollTo(0,0); 
    DataUtil.setTitle('Archive');
  } 
}
</script>

<style>
#projects {
  width: 100vw;
  margin-top: 70px;
}
@media screen and (min-width: 48em) {
  #projects h2 {
    font-size: 44px;
    line-height: 1.15em;
  }
}
@media screen and (min-width: 64em) {
  #projects h2 {
    font-size: 85px;
  }
}
#projects h2.mobile {
  display: block;
  margin-bottom: 20px;
}
@media screen and (min-width: 48em) {
  #projects {
    width: 100vw;
    margin-top: 125px;
  }
  #projects h2.mobile {
    display: none;
  }
}

</style>